import Vue from 'vue'
import VueCookies from 'vue-cookies'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
import { createRouter } from './router'
import { createStore } from './store'
import { sync } from 'vuex-router-sync'

import 'typeface-petit-formal-script'
import 'typeface-bellefair'
import 'typeface-open-sans'


export function createApp() {
  Vue.config.productionTip = false

  const router = createRouter()
  const store = createStore()

  // sync so that route state is available as part of the store
  sync(store, router)

  Vue.use(VueCookies)
  Vue.use(VueAxios, axios)
  // create the app instance, injecting both the router and the store
  const app = new Vue({
    router,
    store,
    render: h => h(App)
  })

  // expose the app, the router and the store.
  return { app, router, store }
}
