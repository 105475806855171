<template>
  <div id="app">
    <div >
      <div class="flex justify-between mx-auto max-w-6xl">
        <router-link to="/" class="p-5">
          <img class="w-20" :src="require('./assets/images/icon150alpha.png')" />
        </router-link>
        <div class="flex justify-end pr-3">
          <router-link to="/tos" class="px-2 sm:px-5 text-xs sm:text-base my-auto text-purple-700 outline-none focus:outline-none hover:text-purple-300 font-body">
            Terms & Conditions
          </router-link>        
          <router-link to="/privacy" class="px-2 sm:px-5 text-xs sm:text-base my-auto text-purple-700 outline-none focus:outline-none hover:text-purple-300 font-body">
            Privacy
          </router-link>
        </div>
      </div>
      <div>
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
}

</script>

<style>
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";


* {
  margin: 0;
  padding: 0;
}

.anim {
  transition: 0.2s;
}

.anim-fast {
  transition: .1s;
}

.anim-slow {
  transition: 1s;
}

.bg-gradient-purple-right {
    background-image: linear-gradient(to right, theme(colors.purple.400), theme(colors.purple.600));
}

@variants hover, disabled {  
  .bg-gradient-purple {
    background-image: linear-gradient(to bottom, theme(colors.purple.200), theme(colors.purple.400), theme(colors.purple.600));
    background-size: 200% 200%;
  }

  .bg-gradient-purple-secondary {
    background-image: linear-gradient(to bottom, theme(colors.gray.300), theme(colors.gray.400), theme(colors.gray.500));
    background-size: 200% 200%;
  }

  .bg-1st-half {
    background-position: 0% 50%;
  }

  .bg-2nd-half {
    background-position: 50% 100%;
  }
  
  .bg-gradient-purple-light {
      background-image: linear-gradient(to bottom, theme(colors.purple.400), theme(colors.purple.600));
  }

  .bg-gradient-gray {
      background-image: linear-gradient(to bottom, theme(colors.gray.400), theme(colors.gray.500));
  }
}
</style>
