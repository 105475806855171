import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export function createStore() {
  return new Vuex.Store({
    state: process.env.NODE_ENV == "development" ? {

    } :
    {

    },
    mutations: {

    },
    actions: {},
    modules: {}
  })
}
