<template>
  <div class="">
    <div class="md:pt-5">
      <div class="pt-2 md:pt-16 px-2 mx-auto text-center text-purple-700 font-body text-2xl md:text-5xl font-medium tracking-tight" >
        Support
      </div>
      <div class="pt-2 md:pt-10 px-2 pb-10 md:pb-64 max-w-4xl mx-auto text-gray-700 font-body text-sm md:text-lg leading-relaxed">
        Tap the settings icon on the home page of the app to be able to view tutorial videos showing how to use the app.

        <br><br>
        <span class="font-semibold tracking-tight">Send your enquiries to citeitnowdotcom@gmail.com</span>
        <br><br>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>