<template>
  <div class="">
    <div class="md:pt-5">
      <div class="pt-2 px-2 md:pt-16 mx-auto text-center text-purple-700 font-body text-2xl md:text-5xl font-medium tracking-tight" >
        Cite It Now : End-text referencing made easy!
      </div>
      <div class="pt-2 px-2 text-center max-w-3xl mx-auto text-gray-700 font-body text-sm md:text-lg leading-relaxed">
        Cite It Now is an iOS and android app that makes generating and managing end-text referencing a breeze. It currently has support for multiple referencing formats in APA and MLA, with many more being added soon.
      </div>
    </div>
    
    <div class="pt-64 md:pt-10 hidden justify-center flex-wrap px-2 md:flex">
      <div class="p-2 md:p-5 w-56 cursor-pointer" @click="playStore()">
        <img class="" :src="require('../assets/images/googleplay.png')" />
      </div>
      <div class="p-2 md:p-5 w-48 cursor-pointer" @click="appStore()">
        <img class="" :src="require('../assets/images/appstore.png')" />
      </div>
    </div>
    
    <div class="">
      <div class="justify-center flex pt-5 md:pt-20 pb-48 ">
        <div class="pt-16 relative w-48">
          <img class="absolute px-8 pt-10 anim" :src="toggle ? prev : prePrev" :class="{ 'opacity-0' : !toggle }" />
          <img class="absolute px-8 pt-10 anim" :src="toggle ? prePrev : prev" :class="{ 'opacity-0' : toggle }" />
          <img class="absolute" :src="require('../assets/images/androidtemplate.png')" />
        </div>
        <div class="relative w-64">
          <img class="absolute px-10 pt-16 anim" :src="toggle ? current : prev" :class="{ 'opacity-0' : !toggle }" />
          <img class="absolute px-10 pt-16 anim" :src="toggle ? prev : current" :class="{ 'opacity-0' : toggle }" />
          <img class="absolute" :src="require('../assets/images/iphonetemplate.png')" />
        </div>
      </div>
    </div>

    <div class="pt-56 md:pt-10 pb-10 flex justify-center flex-wrap px-2 md:hidden">
      <div class="p-2 md:p-5 w-56 cursor-pointer" @click="playStore()">
        <img class="" :src="require('../assets/images/googleplay.png')" />
      </div>
      <div class="p-2 md:p-5 w-48 cursor-pointer" @click="appStore()">
        <img class="" :src="require('../assets/images/appstore.png')" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'

export default {
  name: "home",
  computed:{
    current(){
      return require('../assets/images/'+this.images[this.curIndex]);
    },
    prev(){
      return require('../assets/images/'+this.images[this.prevIndex]);
    },
    prePrev(){
      return require('../assets/images/'+this.images[this.prePrevIndex]);
    }
  },
  data(){
    return {
      images: [
        'home.jpg',
        'new.jpg',
        'productivity.jpg',
        'export.jpg',
      ],
      curIndex: 0,
      prevIndex: 3,
      prePrevIndex: 2,
      toggle: false,
      interval: undefined
    }
  },
  mounted(){
    this.interval = setInterval(() => {
      this.prePrevIndex = this.prevIndex;
      this.prevIndex = this.curIndex;
      this.curIndex += 1;

      if(this.curIndex >= this.images.length){
        this.curIndex = 0;
      }

      this.toggle = !this.toggle;
    }, 3000);
  },
  beforeDestroy(){
    this.interval = null;
  },
  methods:{
    appStore(){
      gtag('event', 'conversion', {
          'send_to': 'AW-652633304/BeEICM7g-NsBENjJmbcC',
          'event_callback': function(){
            window.location = 'https://apps.apple.com/au/app/cite-it-now-easy-referencing/id1508936570';
          }
      });

      setTimeout(function(){
        window.location = 'https://apps.apple.com/au/app/cite-it-now-easy-referencing/id1508936570';
      }, 2000);
    },
    playStore(){
      gtag('event', 'conversion', {
          'send_to': 'AW-652633304/BeEICM7g-NsBENjJmbcC',
          'event_callback': function(){
            window.location = 'https://play.google.com/store/apps/details?id=com.jacobsnell.citeitnow';
          }
      });

      setTimeout(function(){
        window.location = 'https://play.google.com/store/apps/details?id=com.jacobsnell.citeitnow';
      }, 2000);
    }
  },
};
</script>
