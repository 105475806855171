import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Privacy from '../views/Privacy.vue'
import Terms from '../views/ToS.vue'
import Support from '../views/Support.vue'
import Vue from 'vue'

Vue.use(VueRouter)

export const createRouter = () => {
  var routes = [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: Privacy
    },
    {
      path: '/tos',
      name: 'terms',
      component: Terms
    },
    {
      path: '/support',
      name: 'support',
      component: Support
    },
    { path: '*', redirect: '/' }
  ]

  if(process.env.NODE_ENV == "development"){
    /*routes.push(
      
    )*/
  }

  return new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  })
}
